export default {
  defaultOption: {
    shading: null,
    realisticMaterial: {
      textureTiling: 1,
      textureOffset: 0,
      detailTexture: null
    },
    lambertMaterial: {
      textureTiling: 1,
      textureOffset: 0,
      detailTexture: null
    },
    colorMaterial: {
      textureTiling: 1,
      textureOffset: 0,
      detailTexture: null
    },
    hatchingMaterial: {
      textureTiling: 1,
      textureOffset: 0,
      paperColor: '#fff'
    }
  }
};